import { useState } from "react";
import { Modal } from "react-bootstrap";
import { createVariant } from "../../api/internetpackage";

export default function ModalCreateVariant({ show, onClose, onSuccess }) {
  let [form, setForm] = useState({
    name: "",
    description: "",
    amount: "",
    unit: "",
    validity_period: "",
    radius_profile: "",
  });
  let [loading, setLoading] = useState(false);
  const [alert, setAlert] = useState({ show: false, message: "", type: "" });

  const handleClose = () => {
    setForm({
      name: "",
      description: "",
      amount: "",
      unit: "",
      validity_period: "",
      radius_profile: "",
    });
    setAlert({ show: false, message: "", type: "" });
    onClose(false);
  };

  const submitData = (event) => {
    event.preventDefault();
    createVariantAction(form);
  };

  const createVariantAction = async (form) => {
    setLoading(true);
    try {
      let response = await createVariant({ ...form });
      onSuccess(true, response.data.message, response.data.data);
      handleClose();
    } catch (error) {
      let message = "";
      if (error.response) {
        let { data } = error.response;
        message = data.message;
      } else {
        message = "Something Error Happened";
      }

      setAlert({ show: true, message, type: "danger" });
    }

    setLoading(false);
  };

  return (
    <Modal show={show} onHide={() => handleClose()}>
      <form onSubmit={(event) => submitData(event)}>
        <Modal.Header closeButton>
          <Modal.Title>Buat Varian Paket Baru</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {alert.show && (
            <div className={`alert alert-${alert.type} alert-dismissible fade show`} role="alert">
              {alert.message}
              <button
                type="button"
                className="close"
                onClick={() => setAlert({ show: false, message: "", type: "" })}
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
          )}

          <div className="form-group">
            <label htmlFor="">
              Nama Varian <span className="text-danger">*</span>
            </label>
            <input
              type="text"
              className="form-control"
              required
              value={form.name}
              onChange={(event) => setForm({ ...form, name: event.target.value })}
            />
          </div>
          <div className="form-group">
            <label htmlFor="">
              Deskripsi <span className="text-danger">*</span>
            </label>
            <textarea
              type="text"
              required
              className="form-control"
              value={form.description}
              onChange={(event) => setForm({ ...form, description: event.target.value })}
            />
          </div>
          <div className="form-group">
            <label htmlFor="">
              Kuota/Kecepatan <span className="text-danger">*</span>
            </label>
            <input
              type="number"
              className="form-control"
              required
              value={form.amount}
              onChange={(event) => setForm({ ...form, amount: event.target.value })}
            />
          </div>
          <div className="form-group">
            <label htmlFor="">
              Satuan <span className="text-danger">*</span>
            </label>
            <input
              type="text"
              className="form-control"
              required
              value={form.unit}
              onChange={(event) => setForm({ ...form, unit: event.target.value })}
            />
          </div>
          <div className="form-group">
            <label htmlFor="">
              Profile Radius <span className="text-danger">*</span>
            </label>
            <input
              type="text"
              className="form-control"
              required
              value={form.radius_profile}
              onChange={(event) => setForm({ ...form, radius_profile: event.target.value })}
            />
          </div>
          <div className="form-group">
            <label htmlFor="">
              Berlaku (dalam hari)<span className="text-danger">*</span>
            </label>
            <input
              type="number"
              className="form-control"
              required
              value={form.validity_period}
              onChange={(event) => setForm({ ...form, validity_period: event.target.value })}
            />
          </div>
        </Modal.Body>
        <Modal.Footer>
          <button type="button" className="btn btn-secondary" onClick={() => handleClose()}>
            Close
          </button>
          <button type="submit" className="btn btn-primary" disabled={loading}>
            <i className="fas fa-plus"></i> {loading ? "Menunggu ..." : "Buat Varian Paket Baru"}
          </button>
        </Modal.Footer>
      </form>
    </Modal>
  );
}
