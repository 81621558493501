import { useState } from "react";
import moment from "moment";
import { Dropdown, DropdownButton } from "react-bootstrap";
import Box from "../components/Box";
import AlertBox from "../components/AlertBox";
import MainLayout from "../components/layouts/MainLayout";
import Pagination from "../components/Pagination";
import { useAllCustomersHooks } from "../utils/CustomerUtilHooks";
import { convertToRupiah } from "../tools/numerictools";
import { BUILDING_STATUS } from "../tools/userdatatools";
import { useCityRegionHooks } from "../utils/RegionHooks";
import { useControlPermission } from "../utils/ActionPermissionUtils";
import { useInternetPackageListHooks } from "../utils/InternetPackageUtils";
import { useGroupListHooks } from "../utils/ProjectsUtilHooks";
import ModalCustomerDetail from "../components/customer/ModalCustomerDetail";
import ModalCreateTicketCustomer from "../components/ticketing/ModalCreateTicketCustomer";
import ModalChangePackage from "../components/customer/ModalChangePackage";
import ModalChangeDueDate from "../components/customer/ModalChangeDueDate";
import ModalGenerateBilling from "../components/customer/ModalGenerateBilling";
import ModalEditCustomer from "../components/customer/ModalEditCustomer";
import ModalRequestDismantle from "../components/customer/ModalRequestDismantle";
import ModalDownloadCustomer from "../components/customer/ModalDownloadCustomer";
import ModalRequestRelocate from "../components/customer/ModalRequestRelocate";
import ModalGenerateBillingItem from "../components/customer/ModalGenerateBillingItem";
import ModalCustomerLog from "../components/customer/ModalCustomerLog";
import ModalWithdrawDeposit from "../components/customer/ModalWithdrawDeposit";
import ModalUpdateWithdrawDeposit from "../components/customer/ModalUpdateWithdrawDeposit";
import ModalInfoCustomerModem from "../components/customer/ModalInfoCustomerModem";

export default function CustomerPage() {
  let { hasAccess } = useControlPermission();

  let [formFilter, setFormFilter] = useState({
    customer_name: "",
    n: "",
    internet_package: "",
    project_id: "",
    is_active: "",
    building_ownership_status: "",
  });
  let [filter, setFilter] = useState({
    customer_name: "",
    n: "",
    internet_package: "",
    project_id: "",
    is_active: "",
    province: "",
    kota_kab: "",
    building_ownership_status: "",
    page: 1,
    page_size: 10,
  });
  const regionFilter = useCityRegionHooks();
  let [selectedId, setSelectedId] = useState(null);
  const [alert, setAlert] = useState({ show: false, message: "", type: "" });

  let [showModalDetail, setShowModalDetail] = useState(false);
  let [showModalTicket, setShowModalTicket] = useState(false);
  let [showModalChangePack, setShowModalChangePack] = useState(false);
  let [showModalChangeDue, setShowModalChangeDue] = useState(false);
  let [showModalGenerate, setShowModalGenerate] = useState(false);
  let [showModalBillingItem, setShowBillingItem] = useState(false);
  let [showModalEdit, setShowModalEdit] = useState(false);
  let [showModalDismantle, setShowModalDismantle] = useState(false);
  let [showModalDownload, setShowModalDownload] = useState(false);
  let [showModalRelocate, setShowModalRelocate] = useState(false);
  let [showModalLog, setShowModalLog] = useState(false);
  let [showModalWithdraw, setShowModalWithdraw] = useState(false);
  let [showModalWithdrawUpdate, setShowModalWithdrawUpdate] = useState(false);
  let [showModalModem, setShowModalModem] = useState(false);

  const customersData = useAllCustomersHooks({ filter });
  const internetPackage = useInternetPackageListHooks();
  const { projects } = useGroupListHooks();

  const toggleModalDetail = (id) => {
    setSelectedId(id);
    setShowModalDetail(true);
  };

  const toggleModalTicket = (id) => {
    setSelectedId(id);
    setShowModalTicket(true);
  };

  const toggleModalChangePack = (id) => {
    setSelectedId(id);
    setShowModalChangePack(true);
  };

  const toggleModalChangeDue = (id) => {
    setSelectedId(id);
    setShowModalChangeDue(true);
  };

  const toggleModalGenerate = (id) => {
    setSelectedId(id);
    setShowModalGenerate(true);
  };

  const toggleModalBillingItem = (id) => {
    setSelectedId(id);
    setShowBillingItem(true);
  };

  const toggleModalEdit = (id) => {
    setSelectedId(id);
    setShowModalEdit(true);
  };

  const toggleModalDismantle = (id) => {
    setSelectedId(id);
    setShowModalDismantle(true);
  };

  const toggleModalRelocate = (id) => {
    setSelectedId(id);
    setShowModalRelocate(true);
  };

  const toggleModalLog = (id) => {
    setSelectedId(id);
    setShowModalLog(true);
  };

  const toggleModalWithdraw = (id) => {
    setSelectedId(id);
    setShowModalWithdraw(true);
  };

  const toggleModalWithdrawUpdate = (id) => {
    setSelectedId(id);
    setShowModalWithdrawUpdate(true);
  };

  const toggleModalModem = (id) => {
    setSelectedId(id);
    setShowModalModem(true);
  };

  function applyFilter() {
    let form_filter = {
      ...filter,
      ...formFilter,
      province: regionFilter.province,
      kota_kab: regionFilter.city,
      page: 1,
    };
    setFilter(form_filter);
  }

  const resetFilter = () => {
    setFormFilter({
      customer_name: "",
      n: "",
      internet_package: "",
      project_id: "",
      is_active: "",
      building_ownership_status: "",
    });
    setFilter({
      customer_name: "",
      n: "",
      internet_package: "",
      project_id: "",
      is_active: "",
      building_ownership_status: "",
      province: "",
      kota_kab: "",
      page: 1,
      page_size: 10,
    });
    regionFilter.resetRegion();
  };

  const changeFilter = (name, value) => {
    setFormFilter({ ...formFilter, [name]: value });
  };

  const onSuccess = (message) => {
    customersData.getCustomers(filter);
    setAlert({ show: true, message: message, type: "success" });
  };

  const gotoPage = (page) => {
    setFilter({ ...filter, page: page });
  };

  return (
    <MainLayout resource_key="manage-customer">
      <div className="container-fluid">
        <div className="row mb-4">
          <div className="col">
            <h1 className="h3 mb-0 text-gray-800">Pelanggan</h1>
          </div>
          <div className="col-auto">
            {hasAccess("export-customer") && (
              <button className="btn btn-primary" onClick={() => setShowModalDownload(true)}>
                <i className="fas fa-download"></i> Unduh Data Pelanggan
              </button>
            )}
          </div>
        </div>

        <Box
          title={`Daftar Pelanggan (Total Data: ${customersData.meta.count})`}
          loading={customersData.loading}
        >
          <div className="mb-4">
            <div className="row">
              <div className="col-md-2">
                <label htmlFor="customer_number">Pencarian Nomor</label>
                <input
                  type="text"
                  name="customer_number"
                  value={formFilter.n}
                  placeholder="No. HP/No. Reg/No. Pelanggan"
                  onChange={(event) => changeFilter("n", event.target.value)}
                  className="form-control"
                  id="customer_number"
                />
              </div>
              <div className="col-md-2">
                <label htmlFor="customer_name">Nama Pelanggan</label>
                <input
                  type="text"
                  name="customer_name"
                  value={formFilter.customer_name}
                  onChange={(event) => changeFilter("customer_name", event.target.value)}
                  className="form-control"
                  id="customer_name"
                />
              </div>
              <div className="col-md-2">
                <label htmlFor="package_name">Paket</label>
                <select
                  className="form-control input"
                  value={formFilter.internet_package}
                  onChange={(event) => changeFilter("internet_package", event.target.value)}
                >
                  <option value="" disabled>
                    {" "}
                    -- Pilih Paket --
                  </option>
                  {internetPackage.data.map((pack, index) => (
                    <option key={index} value={pack.id}>
                      {pack.name} - ({pack.internet_package_varian.name}) - Rp{" "}
                      {convertToRupiah(pack.price)} - Rp {convertToRupiah(pack.installation_cost)}
                    </option>
                  ))}
                </select>
              </div>
              <div className="col-md-2">
                <label htmlFor="projectFilter">Grup/Project</label>
                <select
                  className="form-control"
                  id="projectFilter"
                  value={formFilter.project_id}
                  onChange={(event) => changeFilter("project_id", event.target.value)}
                >
                  <option value="">SEMUA</option>
                  {projects.map((project, index) => (
                    <option value={project.id} key={index}>
                      {project.name}
                    </option>
                  ))}
                </select>
              </div>
              <div className="col-md-2">
                <label htmlFor="statusFilter">Status</label>
                <select
                  className="form-control"
                  id="statusFilter"
                  value={formFilter.is_active}
                  onChange={(event) => changeFilter("is_active", event.target.value)}
                >
                  <option value="">SEMUA</option>
                  <option value="0">Isolir</option>
                  <option value="1">Aktif</option>
                  <option value="2">Dismantled</option>
                  <option value="3">Aktif Trial</option>
                  <option value="4">Dismantle Trial</option>
                </select>
              </div>
              <div className="col-md-2">
                <label htmlFor="buildingStatusFilter">Status Bangunan</label>
                <select
                  className="form-control"
                  id="buildingStatusFilter"
                  value={formFilter.building_ownership_status}
                  onChange={(event) =>
                    changeFilter("building_ownership_status", event.target.value)
                  }
                >
                  <option value="">SEMUA</option>
                  {Object.keys(BUILDING_STATUS).map((objKey, index) => (
                    <option value={objKey} key={index}>
                      {BUILDING_STATUS[objKey]}
                    </option>
                  ))}
                </select>
              </div>
              <div className="col-md-2">
                <label>Provinsi</label>
                <select
                  className="form-control"
                  value={regionFilter.province}
                  onChange={(event) => regionFilter.changeProvince(event.target.value)}
                >
                  <option value="">-- Semua Provinsi --</option>
                  {regionFilter.provinces.map((province, index) => (
                    <option value={province.province} key={index}>
                      {province.province}
                    </option>
                  ))}
                </select>
              </div>
              <div className="col-md-2">
                <label>Kabupaten</label>
                <select
                  className="form-control"
                  value={regionFilter.city}
                  onChange={(event) => regionFilter.changeCity(event.target.value)}
                >
                  <option value="">-- Semua Kabupaten --</option>
                  {regionFilter.cities.map((city, index) => (
                    <option value={city.kota_kab} key={index}>
                      {city.kota_kab}
                    </option>
                  ))}
                </select>
              </div>
              <div className="col-auto">
                <button
                  className="btn btn-primary"
                  onClick={() => applyFilter()}
                  style={{ marginTop: "32px" }}
                >
                  Filter
                </button>
                <button
                  className="btn btn-default"
                  onClick={() => resetFilter()}
                  style={{ marginTop: "32px" }}
                >
                  Reset
                </button>
              </div>
            </div>
          </div>

          <AlertBox {...alert} setAlert={setAlert} />

          <div style={{ overflowX: "auto" }}>
            <table className="table table-hover table-bordered table-striped">
              <thead>
                <tr>
                  <th></th>
                  <th>No. Pelanggan</th>
                  <th>No. Registrasi</th>
                  <th>Tanggal Terpasang</th>
                  <th>Jatuh Tempo</th>
                  <th>Nama</th>
                  <th>Paket</th>
                  <th>Durasi</th>
                  <th>Biaya</th>
                  <th>Deposit</th>
                  <th>Alamat Pemasangan</th>
                  <th>Nomor HP</th>
                  <th>Kepemilikan Lokasi</th>
                  <th>Grup/Project</th>
                  <th>Serial ONT</th>
                  <th>Status</th>
                </tr>
              </thead>
              <tbody>
                {customersData.customers.length === 0 && (
                  <tr>
                    <td colSpan={14} className="text-center">
                      Tidak ada data
                    </td>
                  </tr>
                )}
                {customersData.customers.map((customer, index) => (
                  <tr key={index}>
                    <td>
                      {hasAccess("view-customer") && (
                        <button
                          className="btn btn-block btn-sm mb-1 btn-primary text-nowrap"
                          onClick={() => toggleModalDetail(customer.id)}
                        >
                          <i className="fas fa-eye"></i> Detail
                        </button>
                      )}
                      {customer.is_active !== 2 &&
                        customer.is_active !== 4 &&
                        hasAccess("create-customer-ticket") && (
                          <button
                            className="btn btn-block btn-sm mb-1 btn-warning text-nowrap"
                            onClick={() => toggleModalTicket(customer.id)}
                          >
                            <i className="fas fa-headset"></i> Buat Tiket
                          </button>
                        )}
                      {customer.is_active !== 2 &&
                        customer.is_active !== 4 &&
                        hasAccess("create-customer-billing-dropdown") && (
                          <DropdownButton
                            id={`dropdown-edit-button-${customer.id}`}
                            title="Generate Billing"
                            size="sm"
                            variant="info"
                            bsPrefix="btn btn-sm btn-block btn-custom-orange mt-2"
                          >
                            {hasAccess("create-customer-billing") && (
                              <Dropdown.Item
                                as="button"
                                onClick={() => toggleModalGenerate(customer.id)}
                                bsPrefix="dropdown-item px-3"
                              >
                                <div className="d-flex align-items-center">
                                  <i className="fas fa-wifi"></i>
                                  <span className="pl-2">Billing Paket</span>
                                </div>
                              </Dropdown.Item>
                            )}
                            {hasAccess("create-customer-billing-item") && (
                              <Dropdown.Item
                                as="button"
                                onClick={() => toggleModalBillingItem(customer.id)}
                                bsPrefix="dropdown-item px-3"
                              >
                                <div className="d-flex align-items-center">
                                  <i className="fas fa-boxes-stacked"></i>
                                  <span className="pl-2">Custom Billing</span>
                                </div>
                              </Dropdown.Item>
                            )}
                          </DropdownButton>
                        )}
                      {customer.is_active !== 2 &&
                        customer.is_active !== 4 &&
                        hasAccess("modify-customer-dropdown") && (
                          <DropdownButton
                            id={`dropdown-edit-button-${customer.id}`}
                            title="Ubah Data   "
                            size="sm"
                            variant="info"
                            bsPrefix="btn btn-sm btn-block btn-info mt-2"
                          >
                            {hasAccess("update-customer") && (
                              <Dropdown.Item
                                as="button"
                                onClick={() => toggleModalEdit(customer.id)}
                                bsPrefix="dropdown-item px-3"
                              >
                                <div className="d-flex align-items-center">
                                  <i className="fas fa-pencil-alt"></i>
                                  <span className="pl-2">Ganti Data Pelanggan</span>
                                </div>
                              </Dropdown.Item>
                            )}
                            {hasAccess("change-customer-package") && (
                              <Dropdown.Item
                                as="button"
                                onClick={() => toggleModalChangePack(customer.id)}
                                bsPrefix="dropdown-item px-3"
                              >
                                <div className="d-flex align-items-center">
                                  <i className="fas fa-exchange-alt"></i>
                                  <span className="pl-2">Ganti Paket</span>
                                </div>
                              </Dropdown.Item>
                            )}
                            {hasAccess("change-customer-plan") && (
                              <Dropdown.Item
                                as="button"
                                onClick={() => toggleModalChangeDue(customer.id)}
                                bsPrefix="dropdown-item px-3"
                              >
                                <div className="d-flex align-items-center">
                                  <i className="fas fa-business-time"></i>
                                  <span className="pl-2">Ganti Waktu Tempo</span>
                                </div>
                              </Dropdown.Item>
                            )}
                            {hasAccess("dismantle-customer") && (
                              <Dropdown.Item
                                as="button"
                                onClick={() => toggleModalDismantle(customer.id)}
                                bsPrefix="dropdown-item px-3"
                              >
                                <div className="d-flex align-items-center">
                                  <i className="fas fa-power-off"></i>
                                  <span className="pl-2">Dismantle</span>
                                </div>
                              </Dropdown.Item>
                            )}
                            {hasAccess("relocate-customer") && (
                              <Dropdown.Item
                                as="button"
                                onClick={() => toggleModalRelocate(customer.id)}
                                bsPrefix="dropdown-item px-3"
                              >
                                <div className="d-flex align-items-center">
                                  <i className="fas fa-location-arrow"></i>
                                  <span className="pl-2">Relocate</span>
                                </div>
                              </Dropdown.Item>
                            )}
                          </DropdownButton>
                        )}
                      {hasAccess("view-customer-log") && (
                        <button
                          className="btn btn-sm btn-custom-purple btn-block text-nowrap mt-2"
                          onClick={() => toggleModalLog(customer.id)}
                        >
                          <i className="fa fa-clock-rotate-left"></i> Riwayat Perubahan
                        </button>
                      )}
                      {customer.is_active !== 2 &&
                        customer.is_active !== 4 &&
                        (hasAccess("restart-modem-customer") ||
                          hasAccess("view-modem-info-customer	")) && (
                          <button
                            className="btn btn-sm btn-custom-teal btn-block mt-2"
                            onClick={() => toggleModalModem(customer.id)}
                          >
                            <i className="fa fa-wifi"></i> Modem Info
                          </button>
                        )}
                    </td>
                    <td>{customer.customer_number}</td>
                    <td className="text-nowrap">{customer.registration.registration_number}</td>
                    <td className="text-nowrap">
                      {moment.unix(customer.createdAt).format("DD MMMM YYYY - HH:mm")}
                    </td>
                    <td className="text-nowrap">
                      {moment(customer.plan.end_date).format("DD MMMM YYYY - HH:mm")}
                    </td>
                    <td className="text-nowrap">{customer.name}</td>
                    <td className="text-nowrap">{customer.internet_package.name}</td>
                    <td className="text-nowrap">
                      {customer.internet_package.internet_package_varian.validity_period} hari
                    </td>
                    <td className="text-nowrap">
                      Rp. {convertToRupiah(customer.internet_package.price)}
                    </td>
                    <td className="text-nowrap">
                      Rp. {convertToRupiah(customer.deposit_amount || 0)}{" "}
                      {(customer.is_active === 2 || customer.is_active === 4) &&
                        hasAccess("view-deposit") &&
                        customer.deposit_amount !== 0 && (
                          <>
                            {customer.deposit_withdrawn === 0 ? (
                              <>
                                {hasAccess("withdrawal-deposit") ? (
                                  <button
                                    className="btn btn-sm btn-primary ml-2"
                                    onClick={() => toggleModalWithdraw(customer.id)}
                                  >
                                    <i className="fa fa-hand-holding-hand"></i> Withdraw
                                  </button>
                                ) : (
                                  <span className="badge badge-primary">Belum withdraw</span>
                                )}
                              </>
                            ) : (
                              <button
                                className="btn btn-sm btn-success ml-2"
                                onClick={() => toggleModalWithdrawUpdate(customer.id)}
                              >
                                <i className="fa fa-check"></i> Sudah Withdraw
                              </button>
                            )}
                          </>
                        )}
                    </td>
                    <td>
                      {customer.installation_address
                        ? `
                          ${customer.installation_address.address} RT ${customer.installation_address.rt}/RW ${customer.installation_address.rw},
                          ${customer.installation_address.addresses.kelurahan}, ${customer.installation_address.addresses.kecamatan}, ${customer.installation_address.addresses.kota_kab}, 
                          ${customer.installation_address.addresses.province}, ${customer.installation_address.addresses.postal_code}
                          `
                        : ""}
                    </td>
                    <td className="text-nowrap">{customer.phone_number}</td>
                    <td className="text-nowrap">
                      {BUILDING_STATUS[customer.building_ownership_status]}
                    </td>
                    <td className="text-nowrap">{customer.project?.name || "-"}</td>
                    <td className="text-nowrap">
                      {customer.inventory_modem_pair?.modem?.serial_number_modem}
                    </td>
                    <td>
                      <CustomerActiveBadge status={customer.is_active} />
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>

          {customersData.meta.count > customersData.meta.page_size && (
            <Pagination
              totalpage={Math.ceil(customersData.meta.count / customersData.meta.page_size)}
              selectedpage={customersData.meta.page}
              clickpage={(page) => gotoPage(page)}
              next={() => gotoPage(customersData.meta.page + 1)}
              prev={() => gotoPage(customersData.meta.page - 1)}
            />
          )}
        </Box>

        <ModalCustomerDetail
          show={showModalDetail}
          id={showModalDetail ? selectedId : ""}
          onClose={() => setShowModalDetail(false)}
        />
        <ModalCreateTicketCustomer
          show={showModalTicket}
          id={showModalTicket ? selectedId : ""}
          onClose={() => setShowModalTicket(false)}
          onSuccess={onSuccess}
        />
        <ModalChangePackage
          show={showModalChangePack}
          id={showModalChangePack ? selectedId : ""}
          onClose={() => setShowModalChangePack(false)}
          onSuccess={onSuccess}
        />
        <ModalChangeDueDate
          show={showModalChangeDue}
          id={showModalChangeDue ? selectedId : ""}
          onClose={() => setShowModalChangeDue(false)}
          onSuccess={onSuccess}
        />
        <ModalGenerateBilling
          show={showModalGenerate}
          id={showModalGenerate ? selectedId : ""}
          onClose={() => setShowModalGenerate(false)}
          onSuccess={onSuccess}
        />
        <ModalGenerateBillingItem
          show={showModalBillingItem}
          id={showModalBillingItem ? selectedId : ""}
          onClose={() => setShowBillingItem(false)}
          onSuccess={onSuccess}
        />
        <ModalEditCustomer
          show={showModalEdit}
          id={showModalEdit ? selectedId : ""}
          onClose={() => setShowModalEdit(false)}
          onSuccess={onSuccess}
        />
        <ModalRequestDismantle
          show={showModalDismantle}
          id={showModalDismantle ? selectedId : ""}
          onClose={() => setShowModalDismantle(false)}
          onSuccess={onSuccess}
        />
        <ModalDownloadCustomer
          show={showModalDownload}
          onClose={() => setShowModalDownload(false)}
          onSuccess={onSuccess}
        />
        <ModalRequestRelocate
          show={showModalRelocate}
          id={showModalRelocate ? selectedId : ""}
          onClose={() => setShowModalRelocate(false)}
          onSuccess={onSuccess}
        />
        <ModalWithdrawDeposit
          show={showModalWithdraw}
          id={showModalWithdraw ? selectedId : ""}
          onClose={() => setShowModalWithdraw(false)}
          onSuccess={onSuccess}
        />
        <ModalUpdateWithdrawDeposit
          show={showModalWithdrawUpdate}
          id={showModalWithdrawUpdate ? selectedId : ""}
          onClose={() => setShowModalWithdrawUpdate(false)}
          onSuccess={onSuccess}
        />
        <ModalCustomerLog
          show={showModalLog}
          id={showModalLog ? selectedId : ""}
          onClose={() => setShowModalLog(false)}
        />
        <ModalInfoCustomerModem
          show={showModalModem}
          id={showModalModem ? selectedId : ""}
          onClose={() => setShowModalModem(false)}
        />
      </div>
    </MainLayout>
  );
}

function CustomerActiveBadge({ status }) {
  switch (status) {
    case 0:
      return <span className="badge badge-warning">Isolir</span>;
    case 1:
      return <span className="badge badge-success">Aktif</span>;
    case 2:
      return <span className="badge badge-danger">Dismantled</span>;
    case 3:
      return <span className="badge badge-success">Aktif Trial</span>;
    case 4:
      return <span className="badge badge-danger">Dismantle Trial</span>;
    default:
      return <></>;
  }
}
