import { apiAuth, apiDownloadAuth } from "./base";

export function getAllInfrastructures(params) {
  let api = apiAuth();
  return api.get("v1/infrastructures", { params });
}

export function getInfrastructureById(id, params) {
  let api = apiAuth();
  return api.get(`/v1/infrastructures/${id}`, { params });
}

export function createNewInfrastructure(data) {
  let api = apiAuth();
  return api.post(`/v1/infrastructures`, data);
}

export function updateInfrastructureById(id, data) {
  let api = apiAuth();
  return api.put(`/v1/infrastructures/${id}`, data);
}

export function deleteInfrastructureById(id) {
  let api = apiAuth();
  return api.delete(`/v1/infrastructures/${id}`);
}

export function addNewIncident(report_id, data) {
  let api = apiAuth();
  return api.post(`/v1/infrastructures/${report_id}/incidents`, data);
}

export function getIncidentById(id, report_id) {
  let api = apiAuth();
  return api.get(`/v1/infrastructures/${report_id}/incidents/${id}`);
}

export function updateIncidentById(id, report_id, data) {
  let api = apiAuth();
  return api.put(`/v1/infrastructures/${report_id}/incidents/${id}`, data);
}

export function deleteIncidentById(id, report_id, data) {
  let api = apiAuth();
  return api.delete(`/v1/infrastructures/${report_id}/incidents/${id}`, data);
}

export function downloadPerformanceProject(params = {}) {
  let api = apiDownloadAuth();
  return api.get("v1/infrastructures/export/xlsx", { params });
}

export function createOlt(data) {
  let api = apiAuth();
  return api.post(`/v1/olt`, data);
}

export function showAllOlt() {
  let api = apiAuth();
  return api.get("v1/olt/show-all");
}

export function getAllOlt(params) {
  let api = apiAuth();
  return api.get("v1/olt", { params });
}

export function getOltById(id) {
  let api = apiAuth();
  return api.get(`/v1/olt/${id}`);
}

export function updateOltById(id, data) {
  let api = apiAuth();
  return api.put(`/v1/olt/${id}`, data);
}

export function deleteOltById(id) {
  let api = apiAuth();
  return api.delete(`/v1/olt/${id}`);
}

export function createOltProfile(data) {
  let api = apiAuth();
  return api.post(`/v1/olt-profiles`, data);
}

export function showAllOltProfile() {
  let api = apiAuth();
  return api.get("v1/olt-profiles/show-all");
}

export function getAllOltProfile(params) {
  let api = apiAuth();
  return api.get("v1/olt-profiles", { params });
}

export function getOltProfileById(id) {
  let api = apiAuth();
  return api.get(`/v1/olt-profiles/${id}`);
}

export function updateOltProfileById(id, data) {
  let api = apiAuth();
  return api.put(`/v1/olt-profiles/${id}`, data);
}

export function deleteOltProfileById(id) {
  let api = apiAuth();
  return api.delete(`/v1/olt-profiles/${id}`);
}
