import { apiAuth, apiDownloadAuth } from "./base";

export function getAllBillings(params) {
  let api = apiAuth();
  return api.get('v1/billings', { params });
}

export function getBillingById(id) {
  let api = apiAuth();
  return api.get(`/v1/billings/id/${id}`);
}

export function updateBillingById(id, data) {
  let api = apiAuth();
  return api.put(`/v1/billings/${id}`, data);
}

export function confirmBillingById(id) {
  let api = apiAuth();
  return api.put(`/v1/billings/${id}/confirm`);
}

export function resetBillingById(id) {
  let api = apiAuth();
  return api.put(`/v1/billings/${id}/revert`);
}

export function downloadBilling(params = {}) {
  let api =  apiDownloadAuth();
  return api.get("v1/billings/export/xlsx", {params});
}

export function downloadBillingInvoiceById(id) {
  let api = apiAuth();
  return api.get(`/v1/billings/${id}/invoice`);
}

export function reminderWhatsappBilling(id) {
  let api =  apiAuth();
  return api.post(`v1/billings/${id}/reminders/whatsapp`);
}

export function getCommmission(params) {
  let api = apiAuth();
  return api.get('v1/registrations/commission', { params });
}

export function getDetailCommissions(id, params) {
  let api = apiAuth();
  return api.get(`v1/registrations/commission/${id}`, { params });
}

export function downloadComissionDetail(id) {
  let api =  apiDownloadAuth();
  return api.get(`v1/registrations/commission/${id}/export/xlsx`);
}

export function getHistoryTransferList(params) {
  let api = apiAuth();
  return api.get('v1/transfer-histories', { params });
}

export function downloadTransferList(params = {}) {
  let api =  apiDownloadAuth();
  return api.get("v1/transfer-histories/export/xlsx", {params});
}

export function updateHistoryById(id, data) {
  let api = apiAuth();
  return api.put(`v1/transfer-histories/${id}`, data);
}

export function getBillingsByIdNumber(id) {
  let api = apiAuth();
  return api.get(`v1/payments/${id}/bills`);
}