import moment from "moment";
import { Modal } from "react-bootstrap";
import { useProjectHooks } from "../../utils/ProjectsUtilHooks";
import { LoadingInsideBox, LoadingInsideBoxWrapper } from "../Loading";

export default function ModalProjectDetail({ show, onClose, id }) {
  let project = useProjectHooks({ id });

  const handleClose = () => {
    onClose();
  };

  return (
    <Modal show={show} onHide={() => handleClose()}>
      <Modal.Header closeButton>
        <Modal.Title>Data Project</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <LoadingInsideBoxWrapper>
          {project.loading && <LoadingInsideBox />}

          <h5 className="mb-3">Detail</h5>
          <table className="table table-bordered">
            <tbody>
              <tr>
                <td>Nama Project</td>
                <td>{project.data.name}</td>
              </tr>
              <tr>
                <td>PIC</td>
                <td>{project.data.pic_name}</td>
              </tr>
              <tr>
                <td>Mitra/Partner</td>
                <td>{project.data.partner?.name || "-"}</td>
              </tr>
              <tr>
                <td>Lokasi</td>
                <td>
                  {project.data.address}, RT{project.data.rt}/RW{project.data.rw},
                  {project.data.addresses &&
                    `${project.data.addresses.kelurahan}, ${project.data.addresses.kecamatan}, ${project.data.addresses.kota_kab}, ${project.data.addresses.province}, ${project.data.addresses.postal_code}`}
                </td>
              </tr>
              <tr>
                <td>Waktu dibuat</td>
                <td>{moment.unix(project.data.createdAt).format("DD MMMM YYYY - HH:mm:ss")}</td>
              </tr>
            </tbody>
          </table>

          <h5 className="my-3">Inventory</h5>
          <table className="table table-striped table-bordered">
            <thead>
              <tr>
                <th>Barang</th>
                <th>Tipe</th>
                <th>Jumlah</th>
                <th>Unit</th>
              </tr>
            </thead>
            <tbody>
              {project.data.project_inventories?.length === 0 && (
                <tr>
                  <td colSpan={4} className="text-center">
                    Tidak ada data
                  </td>
                </tr>
              )}
              {project.data.project_inventories?.map((inventory, yindex) => (
                <tr key={yindex}>
                  <td>{inventory.name}</td>
                  <td>{inventory.type}</td>
                  <td>{inventory.amount}</td>
                  <td>{inventory.unit}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </LoadingInsideBoxWrapper>
      </Modal.Body>
    </Modal>
  );
}
