import moment from "moment";
import { useState } from "react";
import { Modal } from "react-bootstrap";
import { LoadingInsideBox, LoadingInsideBoxWrapper } from "../Loading";
import Pagination from "../Pagination";
import { useRegistrationLogHooks } from "../../utils/RegistrationUtilHooks";
import { LOG_TYPE } from "../../tools/audittools";

export default function ModalRegistrationLog({ show, id, onClose }) {
  const handleClose = () => {
    onClose();
  };

  return (
    <Modal show={show} onHide={() => handleClose()} size="xl">
      <Modal.Header closeButton>
        <Modal.Title>Riwayat Perubahan Data Pendaftar</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <LogHistory id={id} />
      </Modal.Body>
    </Modal>
  );
}

function LogHistory({ id }) {
  let [filter, setFilter] = useState({
    page: 1,
    page_size: 10,
    id: id,
  });

  let { histories, meta, loading, errorMsg } = useRegistrationLogHooks({ filter });

  const gotoPage = (page) => {
    setFilter({ ...filter, page: page });
  };

  return (
    <LoadingInsideBoxWrapper>
      {loading && <LoadingInsideBox />}
      {errorMsg && <div className="alert alert-danger">{errorMsg}</div>}

      <div style={{ overflowX: "auto", marginBottom: "10px" }}>
        <table className="table table-sm table-striped table-hover table-bordered">
          <thead>
            <tr>
              <th>Waktu</th>
              <th>Aksi</th>
              <th>Pengubah</th>
              <th>Log</th>
              <th>Change Log</th>
            </tr>
          </thead>
          <tbody>
            {histories.length === 0 && (
              <tr>
                <td colSpan={5} className="text-center">
                  Tidak ada data
                </td>
              </tr>
            )}
            {histories.map((history, index) => (
              <tr key={index}>
                <td className="text-nowrap">
                  {moment.unix(history.createdAt).format("DD MMM YYYY - HH:mm:ss")}
                </td>
                <td>{LOG_TYPE[history.action_type] || history.action_type}</td>
                <td className="text-nowrap">{history.user.name}</td>
                <td className="text-wrap">{history.request}</td>
                <td className="text-wrap">{history.change_log}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>

      {meta.count > meta.page_size && (
        <Pagination
          totalpage={Math.ceil(meta.count / meta.page_size)}
          selectedpage={meta.page}
          clickpage={(page) => gotoPage(page)}
          next={() => gotoPage(meta.page + 1)}
          prev={() => gotoPage(meta.page - 1)}
        />
      )}
    </LoadingInsideBoxWrapper>
  );
}
