import { useState } from "react";
import { useVariantsList } from "../../utils/InternetPackageUtils";
import Box from "../Box";
import Pagination from "../Pagination";
import ModalCreateVariant from "./ModalCreateVariant";
import ModalDeleteVariant from "./ModalDeleteVariant";
import ModalUpdateVariant from "./ModalUpdateVariant";

export default function BoxVariantPack() {
  let [alert, setAlert] = useState({ show: false, message: "", type: "" });
  let [selectedId, setSelectedId] = useState("");
  let [filter, setFilter] = useState({ q: "", page: 1, page_size: 10 });
  let [formFilter, setFormFilter] = useState({ q: "" });

  let [showModalCreate, setShowModalCreate] = useState(false);
  let [showModalUpdate, setShowModalUpdate] = useState(false);
  let [showModalDelete, setShowModalDelete] = useState(false);

  let variantsData = useVariantsList({ filter });

  const onSuccess = (show, message) => {
    variantsData.getVariants(filter);
    setAlert({ show, message, type: "success" });
  };

  const applyFilter = () => {
    let form_filter = { ...filter, ...formFilter, page: 1 };
    setFilter(form_filter);
  };

  const resetFilter = () => {
    setFormFilter({ q: "" });
    setFilter({ q: "", page: 1, page_size: 10 });
  };

  const changeFilter = (name, value) => {
    setFormFilter({ ...formFilter, [name]: value });
  };

  const toggleOpenModalUpdate = (id = "") => {
    setSelectedId(id);
    setShowModalUpdate(!showModalUpdate);
  };

  const toggleOpenModalDelete = (id = "") => {
    setSelectedId(id);
    setShowModalDelete(!showModalDelete);
  };

  const gotoPage = (page) => {
    setFilter({ ...filter, page: page });
  };

  return (
    <Box title="Varian Paket" loading={variantsData.loading}>
      <div className="row mb-3 justify-content-end">
        <div className="col-auto">
          <button
            className="btn btn-sm btn-primary"
            onClick={() => setShowModalCreate(!showModalCreate)}
          >
            <i className="fa fa-plus"></i> Tambah Varian Paket
          </button>
        </div>
      </div>
      <div className="row mb-4">
        <div className="col-auto">
          <label htmlFor="querySearch">Pencarian</label>
          <input
            type="text"
            name="querySearch"
            value={formFilter.q}
            onChange={(event) => changeFilter("q", event.target.value)}
            className="form-control"
            id="querySearch"
          />
        </div>
        <div className="col-auto">
          <button
            className="btn btn-primary"
            onClick={() => applyFilter()}
            style={{ marginTop: "32px" }}
          >
            Filter
          </button>
          <button
            className="btn btn-default"
            onClick={() => resetFilter()}
            style={{ marginTop: "32px" }}
          >
            Reset
          </button>
        </div>
      </div>
      {alert.show && (
        <div className={`alert alert-${alert.type} alert-dismissible fade show`} role="alert">
          {alert.message}
          <button
            type="button"
            className="close"
            onClick={() => setAlert({ show: false, message: "", type: "" })}
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
      )}
      <div style={{ overflowX: "scroll" }}>
        <table className="table table-striped table-bordered table-sm table-hovered">
          <thead>
            <tr className="text-center">
              <th></th>
              <th>Varian Paket</th>
              <th>Deskripsi</th>
              <th>Kuota/Kecepatan</th>
              <th>Satuan</th>
              <th>Profile Radius</th>
              <th>Berlaku (hari)</th>
            </tr>
          </thead>
          <tbody>
            {variantsData.variants.length === 0 && (
              <tr>
                <td colSpan={6} className="text-center">
                  Tidak ada data
                </td>
              </tr>
            )}
            {variantsData.variants.map((variant, index) => (
              <tr key={index}>
                <td className="text-nowrap">
                  <button
                    className="mx-1 btn btn-sm btn-primary"
                    onClick={() => toggleOpenModalUpdate(variant.id)}
                  >
                    <i className="fa fa-pencil-alt"></i>
                  </button>
                  <button
                    className="mx-1 btn btn-sm btn-danger"
                    onClick={() => toggleOpenModalDelete(variant.id)}
                  >
                    <i className="fa fa-trash"></i>
                  </button>
                </td>
                <td>{variant.name}</td>
                <td>{variant.description}</td>
                <td>{variant.amount}</td>
                <td>{variant.unit}</td>
                <td>{variant.radius_profile}</td>
                <td>{variant.validity_period}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>

      {variantsData.meta.count > variantsData.meta.page_size && (
        <Pagination
          totalpage={Math.ceil(variantsData.meta.count / variantsData.meta.page_size)}
          selectedpage={variantsData.meta.page}
          clickpage={(page) => gotoPage(page)}
          next={() => gotoPage(variantsData.meta.page + 1)}
          prev={() => gotoPage(variantsData.meta.page - 1)}
        />
      )}
      <ModalCreateVariant
        show={showModalCreate}
        onClose={setShowModalCreate}
        onSuccess={onSuccess}
      />
      <ModalUpdateVariant
        show={showModalUpdate}
        onClose={toggleOpenModalUpdate}
        id={showModalUpdate ? selectedId : ""}
        onSuccess={onSuccess}
      />
      <ModalDeleteVariant
        show={showModalDelete}
        onClose={toggleOpenModalDelete}
        id={showModalDelete ? selectedId : ""}
        onSuccess={onSuccess}
      />
    </Box>
  );
}
