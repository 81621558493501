export const LOG_TYPE = {
  "CONFIRM_BILLING": "Konfirmasi Billing",
  "REVERT_BILLING": "Pengembalian Status Billing",
  "CHANGE_PACKAGE": "Pengajuan Ubah Paket",
  "CHANGE_DUE_DATE": "Pengubahan Jatuh Tempo",
  "RELOCATE": "Permintaan Relokasi",
  "DISMANTLE": "Permintaan Dismantle",
  "CREATE_BILLING": "Pembuatan Billing",
  "REGISTER": "Pendaftaran",
  "COVERAGE": "Pengecekan Coverage",
  "UPDATE_REGISTRATION": "Pengubahan Data Registrasi",
  "UPDATE_CUSTOMER": "Pengubahan Data Pelanggan",
  "UPDATE_REGISTRATION_SCHEDULE": "Pengubahan Data Penjadwalan",
  "UPDATE_BILLING": "Ubah Data Billing",
  "ACTIVATION_AUTOMATION": "Aktivasi Otomatis",
  "CHANGE_MODEM_AUTOMATION": "Ubah Modem Otomatis",
  "REBOOT_AUTOMATION": "Restart Modem melalui Sistem",
  "TERMINATE_AUTOMATION": "Terminate Modem melalui Sistem"
}