import { useState, useEffect, useCallback } from "react";
import Box from "../Box";
import AlertBox from "../AlertBox";
import { getAllSettings, saveSettings } from "../../api/setting";
import Timepicker from "../Timepicker";
import { useControlPermission } from "../../utils/ActionPermissionUtils";
import Toggle from "react-toggle";

export default function BoxAllSetting() {
  let { hasAccess } = useControlPermission();

  let [alert, setAlert] = useState({ show: false, message: "", type: "" });
  let [forms, setForms] = useState({
    kpi: {
      total: 0,
      bonus: 0,
    },
    default_inventories: [],
    ba_survey_items: [],
    relocate_fee: 0,
    auto_transfer: {
      start_time: "00:00",
      end_time: "00:00",
    },
    app_version: {
      user: "",
      technician: "",
    },
    modem_activation_automation: {
      status: "off",
      supported_brands: [],
    },
    mrtg_graph: {
      upstream: [],
      exchange: [],
    },
  });
  let [loading, setLoading] = useState(false);

  const populateDateToForm = useCallback((data = []) => {
    let tempForm = {};

    data.forEach((setting) => {
      if (setting.key === "kpi") {
        tempForm.kpi = { ...setting.value };
      }

      if (setting.key === "default-inventories") {
        tempForm.default_inventories = [...setting.value];
      }

      if (setting.key === "relocate-fee") {
        tempForm.relocate_fee = setting.value;
      }

      if (setting.key === "auto-transfer") {
        tempForm.auto_transfer = setting.value;
      }

      if (setting.key === "app-version") {
        tempForm.app_version = setting.value;
      }

      if (setting.key === "ba-survey-items") {
        tempForm.ba_survey_items = setting.value;
      }

      if (setting.key === "mrtg-graph") {
        tempForm.mrtg_graph = setting.value;
      }

      if (setting.key === "modem-activation-automation") {
        tempForm.modem_activation_automation = setting.value;
      }
    });

    setForms(tempForm);
  }, []);

  const fetchSetting = useCallback(async () => {
    setLoading(true);
    try {
      let { data } = await getAllSettings();
      populateDateToForm(data.data);
    } catch (error) {
      let errorMsg = "";
      if (error.response) {
        let { data } = error.response;
        errorMsg = data.message;
      } else {
        errorMsg = "Something Error Happened";
      }
      setAlert({ show: true, errorMsg, type: "danger" });
    }
    setLoading(false);
  }, [populateDateToForm]);

  // Do fetch data when loaded
  useEffect(() => {
    fetchSetting();
  }, [fetchSetting]);

  const sendData = async (form) => {
    setLoading(true);
    try {
      const response = await saveSettings(form);
      setAlert({ show: true, message: response.data.message, type: "success" });
    } catch (error) {
      let message = "";
      if (error.response) {
        const { data } = error.response;
        message = data.message;
      } else {
        message = "Something Error Happened";
      }
      setAlert({ show: true, message, type: "danger" });
    }
    setLoading(false);
  };

  const submitData = (event) => {
    event.preventDefault();

    const formData = {
      kpi: { ...forms.kpi },
      "default-inventories": [...forms.default_inventories],
      "ba-survey-items": forms.ba_survey_items,
      "relocate-fee": forms.relocate_fee,
      "auto-transfer": forms.auto_transfer,
      "app-version": forms.app_version,
      "mrtg-graph": forms.mrtg_graph,
      "modem-activation-automation": forms.modem_activation_automation,
    };

    sendData(formData);
  };

  const onChangeMrtgId = (key, index, value) => {
    let tempForms = { ...forms };
    tempForms.mrtg_graph[key][index]["graph_id"] = value;
    setForms(tempForms);
  };

  const onChangeKpi = (key, value) => {
    let tempForms = { ...forms };
    tempForms.kpi[key] = value;
    setForms(tempForms);
  };

  const onChangeTime = (key, time) => {
    let tempForms = { ...forms };
    tempForms.auto_transfer[key] = time;
    setForms(tempForms);
  };

  const onChangeAppVersion = (key, value) => {
    let tempForms = { ...forms };
    tempForms.app_version[key] = value;
    setForms(tempForms);
  };

  const onChangeDefaultSurvey = (value) => {
    let tempForms = { ...forms };
    tempForms.ba_survey_items = value;
    setForms(tempForms);
  };

  const onChangeDefaultInventory = (value) => {
    let tempForms = { ...forms };
    tempForms.default_inventories = value;
    setForms(tempForms);
  };

  const onChangeModemActivation = (value) => {
    let tempForms = { ...forms };
    tempForms.modem_activation_automation = value;
    setForms(tempForms);
  };

  return (
    <Box title={`General Setting`} loading={loading}>
      <AlertBox {...alert} setAlert={setAlert} />
      <form onSubmit={(event) => submitData(event)}>
        <h5 className="pb-2">Setting KPI</h5>
        <div className="form-group">
          <label>Total</label>
          <input
            type="number"
            min={0}
            required
            className="form-control"
            value={forms.kpi.total}
            onChange={(event) => onChangeKpi("total", event.target.value)}
          />
        </div>
        <div className="form-group">
          <label>Bonus</label>
          <input
            type="number"
            min={0}
            required
            className="form-control"
            value={forms.kpi.bonus}
            onChange={(event) => onChangeKpi("bonus", event.target.value)}
          />
        </div>

        {/* Change Default Item on Survey */}
        <hr className="mt-4" />
        <h5 className="py-2">Setting Default Tambahan Item Survey</h5>
        <DefaultItemSurvey
          formValues={forms.ba_survey_items}
          changeForms={(value) => onChangeDefaultSurvey(value)}
        />

        {/* Change Default Inventory on BAST Installation */}
        <hr className="mt-4" />
        <h5 className="py-2">Setting Default Inventory</h5>
        <DefaultInventoryInstallation
          formValues={forms.default_inventories}
          changeForms={(value) => onChangeDefaultInventory(value)}
        />

        <hr />
        <h5 className="py-2">Setting Relocate</h5>
        <div className="form-group">
          <label>Biaya Relokasi</label>
          <input
            type="number"
            min={0}
            required
            className="form-control"
            value={forms.relocate_fee}
            onChange={(event) => setForms({ ...forms, relocate_fee: event.target.value })}
          />
        </div>

        <h5 className="py-2">Setting Mutasi Otomatis</h5>
        <div className="form-group">
          <label>Waktu cek mutasi BCA otomatis mulai:</label>
          <div className="row">
            <div className="col-sm-4">
              <Timepicker
                time={forms.auto_transfer.start_time}
                onChangeTime={(value) => onChangeTime("start_time", value)}
              />
            </div>
            <div className="col-auto">Sampai</div>
            <div className="col-sm-4">
              <Timepicker
                time={forms.auto_transfer.end_time}
                onChangeTime={(value) => onChangeTime("end_time", value)}
              />
            </div>
          </div>
        </div>

        <h5 className="py-2">Setting Versi Aplikasi</h5>
        <div className="form-group">
          <label>Aplikasi Teknisi Terkini</label>
          <input
            type="text"
            className="form-control"
            value={forms.app_version.technician}
            onChange={(event) => onChangeAppVersion("technician", event.target.value)}
          />
        </div>
        <div className="form-group">
          <label>Aplikasi Pengguna Terkini</label>
          <input
            type="text"
            className="form-control"
            value={forms.app_version.user}
            onChange={(event) => onChangeAppVersion("user", event.target.value)}
          />
        </div>

        <h5 className="py-2">Setting Grafik MRTG</h5>
        <h6 className="pt-2" style={{ fontWeight: "bold" }}>
          Upstream
        </h6>
        {forms.mrtg_graph.upstream.map((graph, index) => (
          <div className="form-group" key={index}>
            <label>ID Grafik {graph.name}</label>
            <input
              type="text"
              className="form-control"
              value={graph.graph_id}
              onChange={(event) => onChangeMrtgId("upstream", index, event.target.value)}
            />
          </div>
        ))}

        <h6 className="pt-2" style={{ fontWeight: "bold" }}>
          Exchange
        </h6>
        {forms.mrtg_graph.exchange.map((graph, index) => (
          <div className="form-group" key={index}>
            <label>ID Grafik {graph.name}</label>
            <input
              type="text"
              className="form-control"
              value={graph.graph_id}
              onChange={(event) => onChangeMrtgId("exchange", index, event.target.value)}
            />
          </div>
        ))}

        {hasAccess("update-setting") && (
          <div className="form-group d-flex justify-content-between pt-4">
            <button type="button" className="btn btn-danger mr-2" onClick={() => fetchSetting()}>
              Batal dan Reset
            </button>
            <button className="btn btn-primary">Simpan</button>
          </div>
        )}
      </form>
    </Box>
  );
}

function DefaultItemSurvey({ formValues, changeForms }) {
  const changeFormSurvey = (index, name, value) => {
    let tempForms = [...formValues];
    tempForms[index][name] = value;
    changeForms(tempForms);
  };

  const addDefault = () => {
    let tempForms = [...formValues];
    tempForms.push({ name: "", price: 0, unit: "" });
    changeForms(tempForms);
  };

  const removeDefault = (index) => {
    if (formValues.length <= 1) {
      return;
    }

    let tempForms = [...formValues];
    if (!(tempForms.length <= 1)) {
      tempForms.splice(index, 1);
      changeForms(tempForms);
    }
  };

  return (
    <>
      {formValues.map((defaultItem, index) => (
        <div className="row" key={index}>
          <div className="col">
            <div className="row">
              <div className="col-12 col-md form-group">
                <label>Default Item #{index + 1}</label>
                <input
                  type="text"
                  className="form-control"
                  value={defaultItem.name}
                  onChange={(event) => changeFormSurvey(index, "name", event.target.value)}
                />
              </div>
              <div className="col-12 col-md form-group">
                <label>Harga per unit #{index + 1}</label>
                <input
                  type="number"
                  className="form-control"
                  value={defaultItem.price}
                  onChange={(event) => changeFormSurvey(index, "price", event.target.valueAsNumber)}
                />
              </div>
              <div className="col-12 col-md form-group">
                <label>Satuan #{index + 1}</label>
                <input
                  type="text"
                  className="form-control"
                  value={defaultItem.unit}
                  onChange={(event) => changeFormSurvey(index, "unit", event.target.value)}
                />
              </div>
            </div>
          </div>
          <div className="col-auto" style={{ paddingTop: "32px" }}>
            <button
              className="btn btn-sm btn-circle btn-dark"
              onClick={() => removeDefault(index)}
              type="button"
            >
              <i className="fa fa-minus"></i>
            </button>
          </div>
        </div>
      ))}

      <button className="btn btn-light" type="button" onClick={() => addDefault()}>
        <i className="fa fa-plus"></i> Tambah barang
      </button>
    </>
  );
}

function DefaultInventoryInstallation({ formValues, changeForms }) {
  const changeFormInventory = (index, name, value) => {
    let tempForms = [...formValues];
    tempForms[index][name] = value;
    changeForms(tempForms);
  };

  const addDefault = () => {
    let tempForms = [...formValues];
    tempForms.push({ name: "", qty: 1, unit: "" });
    changeForms(tempForms);
  };

  const removeDefault = (index) => {
    if (formValues.length <= 1) {
      return;
    }

    let tempForms = [...formValues];
    if (!(tempForms.length <= 1)) {
      tempForms.splice(index, 1);
      changeForms(tempForms);
    }
  };

  return (
    <>
      {formValues.map((inventory, index) => (
        <div className="row" key={index}>
          <div className="col">
            <div className="form-group">
              <div className="row">
                <div className="col-12 col-md form-group">
                  <label>Nama barang {index + 1}</label>
                  <input
                    type="text"
                    className="form-control"
                    required
                    value={inventory.name}
                    onChange={(event) => changeFormInventory(index, "name", event.target.value)}
                  />
                </div>
                <div className="col-12 col-md form-group">
                  <label>Kuantitas barang {index + 1}</label>
                  <input
                    type="number"
                    className="form-control"
                    required
                    min={1}
                    value={inventory.qty}
                    onChange={(event) => changeFormInventory(index, "qty", event.target.value)}
                  />
                </div>
                <div className="col-12 col-md form-group">
                  <label>Unit {index + 1}</label>
                  <input
                    type="text"
                    className="form-control"
                    required
                    min={1}
                    value={inventory.unit}
                    onChange={(event) => changeFormInventory(index, "unit", event.target.value)}
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="col-auto" style={{ paddingTop: "32px" }}>
            <button
              className="btn btn-sm btn-circle btn-dark"
              onClick={() => removeDefault(index)}
              type="button"
            >
              <i className="fa fa-minus"></i>
            </button>
          </div>
        </div>
      ))}
      <button className="btn btn-light" type="button" onClick={() => addDefault()}>
        <i className="fa fa-plus"></i> Tambah barang
      </button>
    </>
  );
}

function ModemAutomaticActivationSetting({ formValues, changeForms }) {
  const toggleStatus = (value) => {
    let form = { ...formValues };
    form.status = value;

    changeForms(form);
  };

  const changeBrand = (index, value) => {
    let form = { ...formValues };
    let supported_brands = [...form.supported_brands];

    supported_brands[index] = value;

    form.supported_brands = supported_brands;

    changeForms(form);
  };

  const removeBrand = (index) => {
    let form = { ...formValues };
    let supported_brands = [...form.supported_brands];

    supported_brands.splice(index, 1);

    form.supported_brands = supported_brands;
    changeForms(form);
  };

  const addBrand = (index) => {
    let form = { ...formValues };
    let supported_brands = [...form.supported_brands];

    supported_brands.push("");

    form.supported_brands = supported_brands;
    changeForms(form);
  };

  return (
    <>
      <div className="form-group row">
        <div className="col-auto">
          <label>Status Fitur</label>
        </div>
        <div className="col-auto">
          <Toggle
            checked={formValues.status === "on"}
            onChange={(event) => {
              if (event.target.checked) {
                toggleStatus("on");
              } else {
                toggleStatus("off");
              }
            }}
          />
        </div>
      </div>
      <div className="form-group">
        <label>Brand Modem yang mendukung aktivasi otomatis</label>
        {formValues.supported_brands.map((brand, i) => (
          <div key={i} className="form-group row">
            <div className="col">
              <input
                className="form-control"
                type="text"
                value={brand}
                onChange={(event) => changeBrand(i, event.target.value)}
              />
            </div>
            <div className="col-auto">
              <div className="btn btn-secondary btn-circle" onClick={() => removeBrand(i)}>
                <i className="fa fa-minus"></i>
              </div>
            </div>
          </div>
        ))}
      </div>
      <div className="form-group">
        <div className="btn btn-primary" onClick={() => addBrand()}>
          <i className="fa fa-plus"></i> Tambah modem yang didukung
        </div>
      </div>
    </>
  );
}
