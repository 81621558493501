// import { BoxManageStatus } from "../components/customer/status/BoxManageStatus";
// import { BoxManageType } from "../components/customer/type/BoxManageType";
import MainLayout from "../components/layouts/MainLayout";
import BoxAllSetting from "../components/setting/BoxAllSetting";
import BoxPaymentMethodSetting from "../components/setting/BoxPaymentMethodSetting";

export default function SettingPage() {
  return (
    <MainLayout resource_key="setting">
      <div className="container-fluid">
        <div className="d-sm-flex align-items-center justify-content-between mb-4">
          <h1 className="h3 mb-0 text-gray-800">Pengaturan</h1>
        </div>
        <div className="row">
          <div className="col-sm-6">
            <BoxAllSetting />
            {/* <BoxSettingDefaultInventory /> */}
            {/* <BoxSettingKpi /> */}
          </div>
          <div className="col-12 col-sm-6">
            <BoxPaymentMethodSetting />
            {/* <BoxManageStatus /> */}
            {/* <BoxManageType /> */}
          </div>
        </div>
      </div>
    </MainLayout>
  );
}
