import { useState } from "react";
import { Modal } from "react-bootstrap";
import Toggle from "react-toggle";
import { updateScheduleDismantleById } from "../../../api/schedules";
import { useAuth } from "../../../utils/AuthHooks";
import { useScheduleDismantleHooks } from "../../../utils/SchedulesUtil";
import AlertBox from "../../AlertBox";

export default function ModalAcceptDismantleModem({ show, id, setShow, onSuccess }) {
  const { data, loading: dataLoading } = useScheduleDismantleHooks({ id });
  const [alert, setAlert] = useState({ show: false, message: "", type: "" });
  let [loading, setLoading] = useState(false);
  let [override, setOverride] = useState(false);
  const auth = useAuth();

  const handleClose = () => {
    if (!loading) {
      setAlert({ show: false, message: "", type: "" });
      setShow();
      setOverride(false);
    }
  };

  const acceptDismantle = async () => {
    setLoading(true);
    try {
      let form = { status: "noc-done", override_automation: String(override) };
      let response = await updateScheduleDismantleById(id, form);
      onSuccess(response.data.message);
      handleClose();
    } catch (error) {
      let message = "";
      if (error.response) {
        let { data } = error.response;
        message = data.message;
      } else {
        message = "Something Error Happened";
      }

      setAlert({ show: true, message, type: "danger" });
    }

    setLoading(false);
  };

  const disabledByRole = (roles = [], allowed = []) => {
    return roles.some((role) => allowed.includes(role.name));
  };

  return (
    <Modal show={show} onHide={() => handleClose()}>
      <Modal.Header closeButton>
        <Modal.Title>Terima Permintaan Dismantle</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <h5 className="text-gray-800">
          Apakah anda yakin untuk melakukan proses dismantle customer "
          {data.registration?.registration_number} - {data.registration?.name}"?
        </h5>
        <AlertBox {...alert} setAlert={setAlert} />

        {data.registration?.inventory_modem_pair?.modem?.automation === 0 ? (
          <div className="alert alert-warning">
            <b>Perhatian!</b> Modem pelanggan ini tidak bisa diterminate secara otomatis. Mohon
            lakukan secara manua.
          </div>
        ) : (
          <div className="form-group row">
            <div className="col-auto">
              <label>Terminate modem manual</label>
            </div>
            <div className="col-auto">
              <Toggle
                checked={override}
                onChange={(event) => {
                  setOverride(event.target.checked);
                }}
                disabled={disabledByRole(auth.user.roles, ["technician"])}
              />
            </div>
          </div>
        )}
      </Modal.Body>
      <Modal.Footer>
        <button className="btn btn-default" onClick={() => handleClose()}>
          Tidak
        </button>
        <button
          className="btn btn-danger"
          onClick={() => acceptDismantle()}
          disabled={loading || dataLoading}
        >
          {loading ? "Menunggu ..." : "Ya"}
        </button>
      </Modal.Footer>
    </Modal>
  );
}
