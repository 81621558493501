import { useState } from "react";
import { awsUploader } from "../api/uploader";

export default function ConfidentialImageUploader({ type, required, onChangeImg }) {
  let [previewImg, setPreviewImg] = useState(null);
  let [loading, setLoading] = useState(false);
  let [error, setError] = useState("");

  const uploadFile = async (formData) => {
    setLoading(true);
    setError("");
    try {
      let { data } = await awsUploader(formData);
      onChangeImg(type, data.data.image_url);
      setPreviewImg(data.data.image_url);
    } catch (error) {
      console.error(error);
      setError("Terjadi masalah");
    }

    setLoading(false);
  };

  const onChangeImage = (event) => {
    let formData = new FormData();
    formData.append("file", event.target.files[0]);
    formData.append("confidential", true);
    uploadFile(formData);
  };

  return (
    <div className="row">
      <div className="col-auto">
        <img
          src={
            previewImg
              ? previewImg
              : "https://via.placeholder.com/600x400.jpg?text=Tidak+Ada+Gambar"
          }
          className="img-thumbnail"
          alt="..."
          style={{ width: "200px" }}
        ></img>
        {loading && (
          <div className="spinner-border text-secondary" role="status">
            <span className="sr-only">Loading...</span>
          </div>
        )}
      </div>
      <div className="col">
        <div className="custom-file" style={{ zIndex: 0 }}>
          <label className="custom-file-label" htmlFor="customFile">
            Pilih file
          </label>
          <input
            type="file"
            required={required}
            className="custom-file-input"
            onChange={(event) => onChangeImage(event)}
            accept="image/*"
          />
          {error && (
            <div className="text-danger text-sm mt-2">
              <i className="fa fa-exclamation-triangle"></i> {error}{" "}
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
