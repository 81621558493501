import { useState } from "react";
import { Modal } from "react-bootstrap";
import { createProject } from "../../api/projects";
import { useAllPartnersHooks } from "../../utils/project/PartnerUtils";
import useRegionHooks from "../../utils/RegionHooks";
import AlertBox from "../AlertBox";
import { RequiredAsterisk } from "../FormHelper";

export default function ModalProjectCreate({ show, onClose, onSuccess }) {
  let [loading, setLoading] = useState(false);
  let [alert, setAlert] = useState({ show: false, message: "", type: "" });
  let [form, setForm] = useState({
    name: "",
    pic_name: "",
    slug: "",
    partner_id: "",
  });
  let address = useRegionHooks();
  let { partners } = useAllPartnersHooks();

  const handleClose = () => {
    resetForm();
    onClose();
  };

  const resetForm = () => {
    setAlert({ show: false, message: "", type: "" });
    setForm({
      name: "",
      pic_name: "",
      slug: "",
      partner_id: "",
    });
    address.resetRegion();
  };

  const sendDataProject = async (formData) => {
    setLoading(true);
    try {
      let response = await createProject(formData);
      onSuccess(true, response.data.message);
      handleClose();
    } catch (error) {
      let message = "";
      if (error.response) {
        let { data } = error.response;
        message = data.message;
      } else {
        message = "Something Error Happened";
      }

      setAlert({ show: true, message, type: "danger" });
    }
    setLoading(false);
  };

  const onSubmitData = (event) => {
    event.preventDefault();
    let formData = {
      ...form,
      address: address.address,
      rt: address.rt,
      rw: address.rw,
      address_id: address.addressId,
    };
    sendDataProject(formData);
  };

  return (
    <Modal show={show} onHide={() => handleClose()} size="md">
      <form onSubmit={(event) => onSubmitData(event)}>
        <Modal.Header closeButton>
          <Modal.Title>Tambah Data Project</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <AlertBox {...alert} setAlert={setAlert} />
          <div className="row">
            <div className="col-lg-12">
              <div className="form-group">
                <label htmlFor="projectname">
                  Nama Project <RequiredAsterisk />
                </label>
                <input
                  type="text"
                  required
                  value={form.name}
                  onChange={(event) => setForm({ ...form, name: event.target.value })}
                  className="form-control"
                />
              </div>
              <div className="form-group">
                <label htmlFor="slugname">Slug</label>
                <input
                  type="text"
                  value={form.slug}
                  onChange={(event) => setForm({ ...form, slug: event.target.value })}
                  className="form-control"
                />
              </div>
              <div className="form-group">
                <label htmlFor="projectname">
                  Nama PIC <i>(Person In Charge)</i> <RequiredAsterisk />
                </label>
                <input
                  type="text"
                  required
                  value={form.pic_name}
                  onChange={(event) => setForm({ ...form, pic_name: event.target.value })}
                  className="form-control"
                />
              </div>
              <div className="form-group">
                <label htmlFor="partnername">Mitra/Partner</label>
                <select
                  name="partnername"
                  id="partnername"
                  className="form-control"
                  value={form.partner_id}
                  onChange={(event) => setForm({ ...form, partner_id: event.target.value })}
                >
                  <option value="">-- Pilih Partner --</option>
                  {partners.map((partner, index) => (
                    <option key={index} value={partner.id}>
                      {partner.name}
                    </option>
                  ))}
                </select>
              </div>
              <div className="form-group">
                <label htmlFor="projectname">
                  Alamat <RequiredAsterisk />
                </label>
                <input
                  type="text"
                  required
                  className="form-control"
                  value={address.address}
                  onChange={(event) => address.setAddress(event.target.value)}
                />
              </div>
              <div className="row">
                <div className="col">
                  <div className="form-group">
                    <label htmlFor="projectname">
                      RT <RequiredAsterisk />
                    </label>
                    <input
                      type="text"
                      required
                      className="form-control"
                      value={address.rt}
                      onChange={(event) => address.setRt(event.target.value)}
                    />
                  </div>
                </div>
                <div className="col-auto">/</div>
                <div className="col">
                  <div className="form-group">
                    <label htmlFor="projectname">
                      RW <RequiredAsterisk />
                    </label>
                    <input
                      type="text"
                      required
                      className="form-control"
                      value={address.rw}
                      onChange={(event) => address.setRw(event.target.value)}
                    />
                  </div>
                </div>
              </div>
              <div className="form-group">
                <label htmlFor="projectname">
                  Provinsi <RequiredAsterisk />
                </label>
                <select
                  name="provinsi"
                  requiredid="provinsi"
                  className="form-control"
                  value={address.province}
                  onChange={(event) => address.changeProvince(event.target.value)}
                >
                  <option value="">-- Pilih Provinsi --</option>
                  {address.provinces.map((province, index) => (
                    <option key={index} value={province.province}>
                      {province.province}
                    </option>
                  ))}
                </select>
              </div>
              <div className="form-group">
                <label htmlFor="projectname">
                  Kota/Kabupaten <RequiredAsterisk />
                </label>
                <select
                  name="kabupaten"
                  requiredid="kabupaten"
                  className="form-control"
                  value={address.city}
                  onChange={(event) => address.changeCity(event.target.value)}
                >
                  <option value="">-- Pilih Kabupaten/Kota --</option>
                  {address.cities.map((city, index) => (
                    <option key={index} value={city.kota_kab}>
                      {city.kota_kab}
                    </option>
                  ))}
                </select>
              </div>
              <div className="form-group">
                <label htmlFor="projectname">
                  Kecamatan <RequiredAsterisk />
                </label>
                <select
                  name="kecamatan"
                  required
                  id="kecamatan"
                  className="form-control"
                  value={address.district}
                  onChange={(event) => address.changeDistrict(event.target.value)}
                >
                  <option value="">-- Pilih Kecamatan --</option>
                  {address.districts.map((district, index) => (
                    <option key={index} value={district.kecamatan}>
                      {district.kecamatan}
                    </option>
                  ))}
                </select>
              </div>
              <div className="form-group">
                <label htmlFor="projectname">
                  Kelurahan <RequiredAsterisk />
                </label>
                <select
                  name="kelurahan"
                  required
                  id="kelurahan"
                  className="form-control"
                  value={address.addressId}
                  onChange={(event) => address.setAddressId(event.target.value)}
                >
                  <option value="">-- Pilih Kelurahan --</option>
                  {address.addresslist.map((kelurahan, index) => (
                    <option key={index} value={kelurahan.id}>
                      {kelurahan.kelurahan}
                    </option>
                  ))}
                </select>
              </div>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <button className="btn btn-secondary" onClick={() => handleClose()} type="button">
            Close
          </button>
          <button className="btn btn-primary" disabled={loading}>
            {loading ? "Membuat project ..." : "Buat Project Baru"}
          </button>
        </Modal.Footer>
      </form>
    </Modal>
  );
}
