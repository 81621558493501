import {
  ResponsiveContainer,
  CartesianGrid,
  XAxis,
  YAxis,
  Tooltip,
  Legend,
  AreaChart,
  Area,
} from "recharts";
import Box from "../Box";

export default function BoxGenericCustomerStats({
  title,
  loading,
  type,
  data,
  min,
  max,
  errorMsg,
  fetched,
  color,
}) {
  return (
    <Box title={title || "Statistik"} loading={loading}>
      {errorMsg && <div className="alert alert-danger">{errorMsg}</div>}

      {fetched && (
        <ResponsiveContainer width="100%" height={300}>
          <AreaChart
            width={500}
            height={300}
            data={data}
            margin={{
              top: 5,
              right: 30,
              left: 10,
              bottom: 5,
            }}
          >
            <defs>
              <linearGradient id={"colorGradient" + type} x1="0" y1="0" x2="0" y2="1">
                <stop offset="5%" stopColor={color} stopOpacity={0.8} />
                <stop offset="95%" stopColor={color} stopOpacity={0.1} />
              </linearGradient>
            </defs>
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey="date" />
            <YAxis domain={[parseInt(min), max || 100]} />
            <Tooltip />
            <Legend />
            {/* <Line type="monotone" dataKey={type} stroke={color} activeDot={{ r: 5 }} dot={{ r: 3, strokeWidth: 4, fill: color }}strokeWidth={3} /> */}
            <Area
              type="monotone"
              dataKey={type}
              fill={`url(#${"colorGradient" + type})`}
              stroke={color}
              dot={{ r: 2, strokeWidth: 4, fill: color }}
              strokeWidth={3}
            />
          </AreaChart>
        </ResponsiveContainer>
      )}
    </Box>
  );
}
