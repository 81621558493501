import { Link } from "react-router-dom";
import { useLocation } from "react-router";
import { useEffect, useState } from "react";
import icon from "../../assets/images/cablestar.png";
import { usePermittedMenuContext } from "../../utils/DynamicMenuUtils";

function CollapsibleMenu({ active, menu }) {
  let location = useLocation();
  const permitted = usePermittedMenuContext();

  let [collapse, setCollapse] = useState(true);

  useEffect(() => {
    if (active) {
      setCollapse(false);
    }
  }, [active]);

  const clickCollapse = () => {
    setCollapse(!collapse);
  };

  const checkAllowed = (menu) => {
    if (menu.permission_key) {
      return permitted.permitted[menu.permission_key];
    }

    return false;
  };

  return (
    <>
      <span
        className={`nav-link ${collapse ? "collapsed" : ""} `}
        style={{ cursor: "pointer" }}
        data-toggle="collapse"
        aria-expanded={false}
        onClick={() => clickCollapse()}
      >
        <i className={menu.icon}></i>
        <span>{menu.name}</span>
      </span>
      {menu.children && (
        <div className={`collapse ${collapse ? "" : "show"} `}>
          <div className="bg-white py-2 collapse-inner rounded">
            <h6 className="collapse-header">{menu.name}</h6>
            {menu.children.map(
              (child, index) =>
                checkAllowed(child) && (
                  <Link
                    to={child.path}
                    className={`collapse-item ${location.pathname === child.path ? "active" : ""}`}
                    key={index}
                  >
                    <span>{child.name}</span>
                  </Link>
                )
            )}
          </div>
        </div>
      )}
    </>
  );
}

export default function Sidebar(props) {
  let location = useLocation();
  let [collapse, setCollapse] = useState(false);

  let [openChild, setOpenChild] = useState("");

  const permitted = usePermittedMenuContext();

  useEffect(() => {
    let path = location.pathname.split("/");
    setOpenChild("/" + path[1]);
  }, [location]);

  const checkAllowed = (menu) => {
    if (menu.permission_key) {
      return permitted.permitted[menu.permission_key];
    }

    return false;
  };

  return (
    <ul
      className={`navbar-nav bg-gradient-purple sidebar sidebar-dark accordion ${
        collapse ? "toggled" : ""
      }`}
      id="accordionSidebar"
    >
      <span className="sidebar-brand d-flex align-items-center justify-content-start">
        <div className="sidebar-brand-icon p-1 bg-white rounded-lg">
          <img src={icon} alt="" style={{ width: "30px" }} />
        </div>
        <div className="sidebar-brand-text mx-3">Cablestar</div>
      </span>
      <hr className="sidebar-divider my-0"></hr>
      <li className={`nav-item ${location.pathname === "/home" ? "active" : ""}`}>
        <Link to={"/home"} className="nav-link">
          <i className="fas fa-home"></i>
          <span>Home</span>
        </Link>
      </li>
      {props.menu.map((menu, index) =>
        checkAllowed(menu) ? (
          <li
            className={`nav-item ${
              openChild === menu.path || location.pathname === menu.path ? "active" : ""
            }`}
            key={index}
          >
            {menu.children ? (
              <CollapsibleMenu active={openChild === menu.path} menu={menu} />
            ) : (
              <Link to={menu.path} className="nav-link">
                <i className={menu.icon}></i>
                <span>{menu.name}</span>
              </Link>
            )}
          </li>
        ) : (
          <div key={index} />
        )
      )}
      <hr className="sidebar-divider my-0 mb-4"></hr>
      <div className="text-center d-none d-md-inline">
        <button
          className="rounded-circle border-0"
          id="sidebarToggle"
          onClick={() => setCollapse(!collapse)}
        ></button>
      </div>
    </ul>
  );
}
