import { Modal } from "react-bootstrap";
import { convertToRupiah } from "../../tools/numerictools";
import { BUILDING_STATUS } from "../../tools/userdatatools";
import { useGetCustomerHooks } from "../../utils/CustomerUtilHooks";
import moment from "moment";

export default function ModalCustomerDetail({ show, id, onClose }) {
  const { data } = useGetCustomerHooks({ id });
  const handleClose = () => {
    onClose();
  };

  return (
    <Modal show={show} onHide={() => handleClose()} size="xl">
      <Modal.Header closeButton>
        <Modal.Title>Data Pelanggan</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="row">
          <div className="col-12 col-sm-6">
            <h5>Data Pelanggan</h5>
            <table className="table table-bordered">
              <tbody>
                <tr>
                  <td>Nama Lengkap</td>
                  <td>{data.name}</td>
                </tr>
                <tr>
                  <td>Nomor Identitas</td>
                  <td>{data.id_number}</td>
                </tr>
                <tr>
                  <td>Nomor NPWP</td>
                  <td>{data.npwp_number}</td>
                </tr>
                <tr>
                  <td>Nomor Registrasi</td>
                  <td>{data.registration?.registration_number}</td>
                </tr>
                <tr>
                  <td>Email</td>
                  <td>{data.email}</td>
                </tr>
                <tr>
                  <td>Tempat Lahir</td>
                  <td>{data.birthdate_place}</td>
                </tr>
                <tr>
                  <td>Tanggal Lahir</td>
                  <td>{data.birthdate}</td>
                </tr>
                <tr>
                  <td>Jenis Kelamin</td>
                  <td>{data.gender}</td>
                </tr>
                <tr>
                  <td>Nomor Telepon</td>
                  <td>{data.phone_number}</td>
                </tr>
                <tr>
                  <td>Alamat</td>
                  <td>{data.address}</td>
                </tr>
                <tr>
                  <td>RT</td>
                  <td>{data.rt}</td>
                </tr>
                <tr>
                  <td>RW</td>
                  <td>{data.rw}</td>
                </tr>
                <tr>
                  <td>Kelurahan</td>
                  <td>{data.addresses && data.addresses.kelurahan}</td>
                </tr>
                <tr>
                  <td>Kecamatan</td>
                  <td>{data.addresses && data.addresses.kecamatan}</td>
                </tr>
                <tr>
                  <td>Kota/Kabupaten</td>
                  <td>{data.addresses && data.addresses.kota_kab}</td>
                </tr>
                <tr>
                  <td>Provinsi</td>
                  <td>{data.addresses && data.addresses.province}</td>
                </tr>
                <tr>
                  <td>Kode Pos</td>
                  <td>{data.addresses && data.addresses.postal_code}</td>
                </tr>
                <tr>
                  <td>Status Pelanggan</td>
                  <td>
                    <CustomerActiveBadge status={data.is_active} />
                  </td>
                </tr>
              </tbody>
            </table>

            <h5>Data Pemasangan</h5>
            <table className="table table-bordered">
              <tbody>
                <tr>
                  <td>Alamat</td>
                  <td>{data.installation_address?.address}</td>
                </tr>
                <tr>
                  <td>RT</td>
                  <td>{data.installation_address?.rt}</td>
                </tr>
                <tr>
                  <td>RW</td>
                  <td>{data.installation_address?.rw}</td>
                </tr>
                <tr>
                  <td>Kelurahan</td>
                  <td>
                    {data.installation_address?.addresses &&
                      data.installation_address?.addresses.kelurahan}
                  </td>
                </tr>
                <tr>
                  <td>Kecamatan</td>
                  <td>
                    {data.installation_address?.addresses &&
                      data.installation_address?.addresses.kecamatan}
                  </td>
                </tr>
                <tr>
                  <td>Kota/Kabupaten</td>
                  <td>
                    {data.installation_address?.addresses &&
                      data.installation_address?.addresses.kota_kab}
                  </td>
                </tr>
                <tr>
                  <td>Provinsi</td>
                  <td>
                    {data.installation_address?.addresses &&
                      data.installation_address?.addresses.province}
                  </td>
                </tr>
                <tr>
                  <td>Kode Pos</td>
                  <td>
                    {data.installation_address?.addresses &&
                      data.installation_address?.addresses.postal_code}
                  </td>
                </tr>
                <tr>
                  <td>Status Bangunan</td>
                  <td>{BUILDING_STATUS[data.building_ownership_status]}</td>
                </tr>
                <tr>
                  <td>Grup/Project</td>
                  <td>{data.project?.name || "-"}</td>
                </tr>
                <tr>
                  <td>Sales Affiliator</td>
                  <td>{data.referral?.name || "-"}</td>
                </tr>
              </tbody>
            </table>
          </div>
          <div className="col-12 col-sm-6">
            <h5>Data Paket</h5>
            <table className="table table-bordered">
              <tbody>
                <tr>
                  <td>Nama Paket</td>
                  <td>{data.internet_package?.name}</td>
                </tr>
                <tr>
                  <td>Tipe Paket</td>
                  <td>{data.internet_package?.internet_package_type.name}</td>
                </tr>
                <tr>
                  <td>Varian Paket</td>
                  <td>{data.internet_package?.internet_package_varian.name}</td>
                </tr>
                <tr>
                  <td>Biaya Paket</td>
                  <td>Rp. {convertToRupiah(data.internet_package?.price || 0)}</td>
                </tr>
                <tr>
                  <td>Deposit</td>
                  <td>
                    {data.deposit_amount ? `Rp. ${convertToRupiah(data.deposit_amount)}` : `-`}
                  </td>
                </tr>
                <tr>
                  <td>Durasi Paket</td>
                  <td>{data.internet_package?.internet_package_varian.validity_period} hari</td>
                </tr>
              </tbody>
            </table>

            {data.inventory_modem_pair && (
              <>
                <h5>Data Perangkat</h5>
                <table className="table table-bordered">
                  <tbody>
                    <tr>
                      <td>Serial Modem</td>
                      <td>{data.inventory_modem_pair?.modem.serial_number_modem}</td>
                    </tr>
                    <tr>
                      <td>Username</td>
                      <td>{data.inventory_modem_pair?.modem.pppoe_username}</td>
                    </tr>
                    <tr>
                      <td>Password</td>
                      <td>{data.inventory_modem_pair?.modem.pppoe_password}</td>
                    </tr>
                    <tr>
                      <td>Nomor ODP</td>
                      <td>{data.inventory_modem_pair?.modem.odp}</td>
                    </tr>
                    <tr>
                      <td>Nomor OLT</td>
                      <td>{data.inventory_modem_pair?.modem.olt}</td>
                    </tr>
                    <tr>
                      <td>Nomor ODC</td>
                      <td>{data.inventory_modem_pair?.modem.odc}</td>
                    </tr>
                    <tr>
                      <td>Framed Pool</td>
                      <td>{data.inventory_modem_pair?.modem.framed_pool}</td>
                    </tr>
                  </tbody>
                </table>
              </>
            )}

            {data.registration?.bast_dismantle && (
              <>
                <h5>Data BAST Dismantle</h5>
                <TableBastDismantle bast={data.registration.bast_dismantle} />
              </>
            )}
            <h5>Data BAST Instalasi</h5>
            <table className="table table-bordered">
              <tbody>
                <tr>
                  <td>Latitude</td>
                  <td>{data.registration?.bast.latitude || "-"}</td>
                </tr>
                <tr>
                  <td>Longitude</td>
                  <td>{data.registration?.bast.longitude || "-"}</td>
                </tr>
                <tr>
                  <td>Serial Modem</td>
                  <td>{data.registration?.bast?.serial_number_modem}</td>
                </tr>
                <tr>
                  <td>Nomor ODP</td>
                  <td>{data.registration?.bast?.odp}</td>
                </tr>
                <tr>
                  <td>Nomor OLT</td>
                  <td>{data.registration?.bast?.olt}</td>
                </tr>
                <tr>
                  <td>Nomor ODC</td>
                  <td>{data.registration?.bast?.odc}</td>
                </tr>
                <tr>
                  <td>Catatan</td>
                  <td>{data.registration?.bast.note || "-"}</td>
                </tr>
                <tr>
                  <td>Catatan Material</td>
                  <td>{data.registration?.bast.material_note || "-"}</td>
                </tr>
                <tr>
                  <td>Foto KTP</td>
                  <td>
                    <img
                      src={data.registration?.bast.id_image_url}
                      alt="Foto KTP"
                      style={{ width: "150px" }}
                    />
                  </td>
                </tr>
                <tr>
                  <td>Foto Lokasi Modem</td>
                  <td>
                    <img
                      src={data.registration?.bast.modem_image_url}
                      alt="Foto Lokasi Modem"
                      style={{ width: "150px" }}
                    />
                  </td>
                </tr>
                <tr>
                  <td>Foto Tiang</td>
                  <td>
                    <img
                      src={data.registration?.bast.pillar_image_url}
                      alt="Foto Tiang"
                      style={{ width: "150px" }}
                    />
                  </td>
                </tr>
                <tr>
                  <td>Hasil Uji Kecepatan</td>
                  <td>
                    {data.registration?.bast.speed_test_image_url ? (
                      <img
                        src={data.registration?.bast.speed_test_image_url}
                        alt="Hasil Uji Kecepatan"
                        style={{ width: "150px" }}
                      />
                    ) : (
                      "Tidak ada gambar"
                    )}
                  </td>
                </tr>
                <tr>
                  <td>Tanda Tangan Pelanggan</td>
                  <td>
                    <img
                      src={data.registration?.bast.bast_image_url}
                      alt="Foto Scan BAST"
                      style={{ width: "150px" }}
                    />
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
}

function TableBastDismantle({ bast }) {
  let inventoryImg = "";
  let signatureImg = "";

  bast.attachments.forEach((attachment) => {
    if (attachment.type === "inventory") {
      inventoryImg = attachment.attachment_url;
    }

    if (attachment.type === "signature") {
      signatureImg = attachment.attachment_url;
    }
  });

  return (
    <table className="table table-bordered">
      <tbody>
        <tr>
          <td>Tgl. BAST Dismantle</td>
          <td>
            {bast.createdAt ? moment.unix(bast.createdAt).format("DD MMM YYYY - HH:mm") : "-"}
          </td>
        </tr>
        <tr>
          <td>SN Modem</td>
          <td>{bast.serial_number_modem || "-"}</td>
        </tr>
        <tr>
          <td>ODP</td>
          <td>{bast.odp || "-"}</td>
        </tr>
        <tr>
          <td>ODC</td>
          <td>{bast.odc || "-"}</td>
        </tr>
        <tr>
          <td>OLT</td>
          <td>{bast.olt || "-"}</td>
        </tr>
        <tr>
          <td>Foto Perangkat</td>
          <td>
            <img src={inventoryImg} alt="Foto Lokasi Modem" width={200} />
          </td>
        </tr>
        <tr>
          <td>Tanda Tangan</td>
          <td>
            <img src={signatureImg} alt="Tanda Tangan" width={200} />
          </td>
        </tr>
      </tbody>
    </table>
  );
}

function CustomerActiveBadge({ status }) {
  switch (status) {
    case 0:
      return <span className="badge text-md badge-warning">Isolir</span>;
    case 1:
      return <span className="badge text-md badge-success">Aktif</span>;
    case 2:
      return <span className="badge text-md badge-danger">Dismantled</span>;
    case 3:
      return <span className="badge text-md badge-success">Aktif Trial</span>;
    case 4:
      return <span className="badge text-md badge-danger">Dismantle Trial</span>;
    default:
      return <></>;
  }
}
