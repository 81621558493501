import { useEffect, useState } from "react";
import { useHistory } from "react-router";
import { useAuth } from "../utils/AuthHooks";

function LoginPage() {
  const [form, setForm] = useState({ username: "", password: "" });
  const auth = useAuth();

  let history = useHistory();

  const changeField = (field, value) => {
    setForm({ ...form, [field]: value });
  };

  const formLoginSubmit = async (event) => {
    event.preventDefault();
    auth.login(form);
  };

  useEffect(() => {
    if (auth.isAuthenticated) {
      history.push("/home");
    }
  }, [auth.isAuthenticated, history]);

  return (
    <div
      className="login-page-wrapper bg-gradient-purple"
      style={{ minHeight: "100vh", padding: "100px 0" }}
    >
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-12 col-md-8 col-lg-6">
            <div className="card o-hidden border-0 shadow-lg">
              <div className="card-body p-5">
                <div className="row">
                  <div className="col-12">
                    <div className="text-center mb-5">
                      {/* <img src={image} className="mb-3" style={{maxWidth: "200px"}}/> */}
                      <h1 className="h4 text-gray-900 mb-4">Login Cablestar Dashboard</h1>
                    </div>
                  </div>
                  <div className="col-12">
                    {auth.errorMsg && <div className="alert alert-danger">{auth.errorMsg}</div>}
                    <form className="user" onSubmit={(event) => formLoginSubmit(event)}>
                      <div className="form-group">
                        <input
                          type="text"
                          className="form-control form-control-user"
                          id="username"
                          aria-describedby="usernameHelp"
                          value={form.username}
                          onChange={(event) => changeField("username", event.target.value)}
                          placeholder="Enter username ..."
                        />
                      </div>
                      <div className="form-group">
                        <input
                          type="password"
                          className="form-control form-control-user"
                          id="password"
                          value={form.password}
                          onChange={(event) => changeField("password", event.target.value)}
                          placeholder="Password"
                        />
                      </div>
                      <button type="submit" className="btn btn-primary btn-user btn-block">
                        Login
                      </button>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default LoginPage;
