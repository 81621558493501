import { useEffect, useState } from "react";
import { getAllBillings, getBillingById } from "../../api/finance";

export function useBillingsHooks({ filter }) {
  const [data, setData] = useState([]);
  const [meta, setMeta] = useState({count: 0, page: 1, page_size: 10});
  const [errorMsg, setErrorMsg] = useState('');
  const [loading, setLoading] = useState(false);

  const getBillings = async (filter = {}) => {
    setLoading(true);
    setErrorMsg('')
    try {
      const { data } = await getAllBillings({ ...filter });
      setData(data.data);
      setMeta(data.pagination);
    } catch (error) {
      if (error.response) {
        let { data } =  error.response;
        setErrorMsg(data.message)
      } else {
        setErrorMsg("Something Error Happened")
      }
    }
    setLoading(false);
  }

  useEffect(() => {
    getBillings(filter)
  }, [filter])

  return {
    data,
    meta,
    loading,
    errorMsg,
    getBillings
  }
}

export function useBillingIdHooks({ id }) {
  let [data, setData] = useState({});
  let [success, setSuccess] = useState(null);
  let [fetched, setFetched] = useState(false);
  let [error, setError] = useState(null);
  let [loading, setLoading] = useState(false);

  useEffect(() => {
    if (id) {
      getBilling(id)
    }
  }, [id]);

  const getBilling = async (id) => {
    setData({});
    setError("");
    setLoading(true);
    setSuccess(null);
    setFetched(false)
    try {
      let response = await getBillingById(id);
      setData(response.data.data);
      setFetched(true)
    } catch (error) {
      if (error.response) {
        let { data } =  error.response;
        setError(data.message)
      } else {
        setError("Something Error Happened")
      }
    }
    setLoading(false);
  };

  return {
    data,
    fetched,
    success,
    error,
    loading,
    getBilling,
  }
}
