import { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import { updateTechnicianById } from "../../api/technician";
import { useTechnicianHooks } from "../../utils/TechniciansUtils";
import { useCityRegionHooks } from "../../utils/RegionHooks";
import ImageUploader from "../ImageUploader";
import { useTechnicianRolesListHooks } from "../../utils/PermissionManageUtils";
import { RequiredAsterisk } from "../FormHelper";

export default function ModalUpdateTechnician({ show, id, onClose, onSuccess }) {
  let technician = useTechnicianHooks({ id });

  let [form, setForm] = useState({
    name: "",
    id_card_number: "",
    email: "",
    role: "",
    phone_number: "",
    image_url: "",
  });
  let [loading, setLoading] = useState(false);
  const [alert, setAlert] = useState({ show: false, message: "", type: "" });

  const [roleOpt, setRoleOpt] = useState([]);
  const { roles } = useTechnicianRolesListHooks();

  const region = useCityRegionHooks();

  useEffect(() => {
    let rolesTemp = {};

    roles.forEach((role) => {
      if (role.name === "technician" || role.name === "helper") {
        rolesTemp[role.name] = role.id;
      }
    });

    setRoleOpt(rolesTemp);
  }, [roles]);

  useEffect(() => {
    if (technician.fetched) {
      setForm({
        name: technician.data.name,
        id_card_number: technician.data.id_card_number,
        email: technician.data.email,
        phone_number: technician.data.phone_number,
        image_url: technician.data.image_url,
        role: technician.data.role.toLowerCase(),
      });

      if (technician.data.addresses) {
        region.setProvince(technician.data.addresses.province);
        region.setCity(technician.data.address_id);
      }
    }
  }, [technician.fetched]);

  const onChangeImg = (type, image_url) => {
    setForm({ ...form, [type]: image_url });
  };

  const handleClose = () => {
    setForm({ name: "", id_card_number: "", email: "", role: "", phone_number: "", image_url: "" });
    region.resetRegion();
    onClose(false);
  };

  const submitData = (event) => {
    event.preventDefault();
    let formData = { ...form, address_id: String(region.city), roles: [] };
    formData.roles = [
      {
        id: roleOpt[formData.role],
      },
    ];
    updateTechnician(formData);
  };

  const updateTechnician = async (form) => {
    setLoading(true);
    try {
      let response = await updateTechnicianById(id, form);
      onSuccess(true, response.data.message);
      handleClose();
    } catch (error) {
      let message = "";
      if (error.response) {
        let { data } = error.response;
        message = data.message;
      } else {
        message = "Something Error Happened";
      }

      setAlert({ show: true, message, type: "danger" });
    }

    setLoading(false);
  };

  return (
    <Modal show={show} onHide={() => handleClose()}>
      <form onSubmit={(event) => submitData(event)}>
        <Modal.Header closeButton>
          <Modal.Title>Edit Data Teknisi</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {alert.show && (
            <div className={`alert alert-${alert.type} alert-dismissible fade show`} role="alert">
              {alert.message}
              <button
                type="button"
                className="close"
                onClick={() => setAlert({ show: false, message: "", type: "" })}
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
          )}

          <div className="form-group">
            <label htmlFor="">
              Nama Teknisi <RequiredAsterisk />
            </label>
            <input
              type="text"
              className="form-control"
              required
              value={form.name}
              onChange={(event) => setForm({ ...form, name: event.target.value })}
            />
          </div>
          <div className="form-group">
            <label htmlFor="">
              Nomor KTP Teknisi <RequiredAsterisk />
            </label>
            <input
              type="text"
              className="form-control"
              required
              value={form.id_card_number}
              onChange={(event) => setForm({ ...form, id_card_number: event.target.value })}
            />
          </div>
          <div className="form-group">
            <label htmlFor="EmailForm">
              Alamat Email <RequiredAsterisk />
            </label>
            <input
              type="email"
              id="EmailForm"
              className="form-control"
              required
              value={form.email}
              onChange={(event) => setForm({ ...form, email: event.target.value })}
            />
          </div>
          <div className="form-group">
            <label htmlFor="">
              Nomor Telepon <RequiredAsterisk />
            </label>
            <input
              type="number"
              className="form-control"
              required
              value={form.phone_number}
              onChange={(event) => setForm({ ...form, phone_number: event.target.value })}
            />
          </div>
          <div className="form-group">
            <label htmlFor="">
              Role <RequiredAsterisk />
            </label>
            <select
              className="form-control"
              required
              value={form.role}
              onChange={(event) => setForm({ ...form, role: event.target.value })}
            >
              <option value="" disabled>
                --- Pilih Role ---
              </option>
              <option value="technician">TECHNICIAN</option>
              <option value="helper">HELPER</option>
            </select>
          </div>
          <div className="form-group mb-4">
            <label>Foto Akun</label>
            <ImageUploader
              image={form.image_url}
              type="image_url"
              onChangeImg={onChangeImg}
              required={!form.image_url}
            />
          </div>
          <div className="form-group">
            <label htmlFor="provinsi">
              Provinsi <RequiredAsterisk />
            </label>
            <select
              name="provinsi"
              id="provinsi"
              className="form-control"
              value={region.province}
              onChange={(event) => region.setProvince(event.target.value)}
              required
            >
              <option value="">-- Pilih Provinsi --</option>
              {region.provinces.map((province, index) => (
                <option key={index} value={province.province}>
                  {province.province}
                </option>
              ))}
            </select>
          </div>
          <div className="form-group">
            <label htmlFor="kabupaten">
              Area Kabupaten/Kota <RequiredAsterisk />
            </label>
            <select
              name="kabupaten"
              id="kabupaten"
              className="form-control"
              value={region.city}
              onChange={(event) => region.setCity(event.target.value)}
              required
            >
              <option value="">-- Pilih Kabupaten/Kota --</option>
              {region.cities.map((city, index) => (
                <option key={index} value={city.id}>
                  {city.kota_kab}
                </option>
              ))}
            </select>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <button type="button" className="btn btn-secondary" onClick={() => handleClose()}>
            Close
          </button>
          <button type="submit" className="btn btn-primary" disabled={loading}>
            <i className="fas fa-save"></i> Simpan Data Teknisi
          </button>
        </Modal.Footer>
      </form>
    </Modal>
  );
}
